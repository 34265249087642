import React, { useEffect, useState } from 'react'
import { FileSession } from '../../models/FileSession';
import { useLocation } from 'react-router-dom';
import { PdfJsPreviewer } from './PdfJsPreviewer';
import FileManagerRichTextEditorPreview from './FileManagerRichTextEditorPreview';
import FileManagerSpreadsheetPreviewer from './FileManagerSpreadsheetPreviewer';
import FileManagerDocumentPreviewer from './FileManagerDocumentPreviewer';
import { fileOpenService, filePreviewService } from '../../apis/fileService';
import { FileDetailsObject } from '../../models/FileDetails';

interface Props {
    fileSession: FileSession;
}


export default function FileManagerPreviewer({ fileSession }: Props) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const fileId = queryParams.get('uuid') ?? "";
    const [currentFileId, setCurrentFileId] = useState<string>();
    const [fileName, setFileName] = useState<string>();
    const [fileExt, setFileExt] = useState<string>();
    const [refresh, doRefresh] = useState(0);
    const [base64String, setBase64String] = useState<string>();


    useEffect(() => {
        async function fetchSelectedFileContent() {
            const result = await filePreviewService(fileId, fileSession);
            var data = result?.data?.Data;
            if (data) {

                try {
                    setBase64String(data.FileBase64String);
                    setFileName(data.FileName);
                    setCurrentFileId(data.Id);
                    setFileExt("." + data.FileName.split('.').pop() ?? "");
                } catch (e) {
                    console.log(e);
                }
            }
        }
        fetchSelectedFileContent().then(() => { });
    }, []);

    const isTypeAvailable = (fileType: string) => {

        if (!fileType) {
            return false;
        }

        let fType = fileType.toLowerCase();
        const availableTypes = ['.png', '.jpeg', '.jpg', '.tiff', '.gif', '.xml', '.jpeg', '.gif', '.ico', '.pdf', '.txt', '.docx', '.xlsx', '.csv', '.xls', '.ods', 'xlsb'];
        debugger;
        return availableTypes.includes(fType);
    };




    return (
        <div>
            {
                base64String ?


                    <div >
                        <div className="sidebar-child" >

                            <div id="card-preview" className="e-card"
                                style={{
                                    backgroundColor: '#f3f2f1',
                                    height: '95vh',
                                    visibility: `${(fileName) ? `visible` : `hidden`}`
                                }}>
                                {
                                    (
                                        fileExt?.toLowerCase() === ".png" ||
                                        fileExt?.toLowerCase() === ".jpeg" ||
                                        fileExt?.toLowerCase() === ".jpg" ||
                                        fileExt?.toLowerCase() === ".tiff" ||
                                        fileExt?.toLowerCase() === ".tif" ||
                                        fileExt?.toLowerCase() === ".gif" ||
                                        fileExt?.toLowerCase() === ".ico"
                                    ) &&

                                    <img
                                        src={
                                            `data:image/${fileExt?.toLowerCase().substring(1)};base64,` +
                                            (base64String)
                                        }
                                        alt={fileName}
                                        style={{
                                            'height': 'auto',
                                            'width': '100%',
                                            'aspectRatio': '1/1',
                                            'overflowY': 'auto',
                                        }} />
                                }


                                {
                                    (fileName
                                        && fileExt?.toLowerCase() === ".pdf"
                                    ) &&

                                    <PdfJsPreviewer pdfPath={encodeURIComponent(currentFileId ?? "")}
                                        fileId={encodeURIComponent(currentFileId ?? "")}
                                        fileName={encodeURIComponent(fileName)}
                                        fileProvider={""}
                                        fileSession={fileSession} />
                                }


                                {
                                    (fileName
                                        && fileExt?.toLowerCase() === ".txt") &&

                                    <div style={{
                                        'maxHeight': '50vh',
                                        'width': '100%',
                                        'overflowY': 'auto'
                                    }} >

                                        <FileManagerRichTextEditorPreview
                                            html={base64String} />
                                    </div>
                                }

                                {
                                    (fileName
                                        && (fileExt?.toLowerCase() === ".xlsx" ||
                                            fileExt?.toLowerCase() === ".xls" ||
                                            fileExt?.toLowerCase() === ".csv" ||
                                            fileExt?.toLowerCase() === ".ods" ||
                                            fileExt?.toLowerCase() === ".xlsb")) &&


                                    <FileManagerSpreadsheetPreviewer
                                        base64FileString={base64String}
                                        fileName={fileName}
                                        fileSession={fileSession} />
                                }

                                {
                                    (fileName
                                        && (fileExt === ".docx" ||
                                            fileExt === ".dotm" ||
                                            fileExt === ".docm" ||
                                            fileExt === ".rtf" ||
                                            fileExt === ".dot" ||
                                            fileExt === ".xml" ||
                                            fileExt === ".html" ||
                                            fileExt === ".doc"
                                        )) &&

                                    <div style={{
                                        'maxHeight': '85vh',
                                        'width': '100%',
                                        'overflowY': 'auto'
                                    }} >
                                        <FileManagerDocumentPreviewer
                                            filePath={currentFileId ?? ""}
                                            fileName={fileName}
                                            fileProvider={""}
                                            refresh={refresh}
                                            fileSession={fileSession} />
                                    </div>
                                }



                            </div>
                        </div>
                    </div>




                    :


                    <div>Loading please wait...</div>

            }
        </div>
    )
}
